import { FC } from 'react';
import useSWR from 'swr';

// Components
import CardsImage from '../Cards/CardsImage/CardsImage';

// Libraries
import swrUrlParser from '../../libs/swrUrlParser/swrUrlParser';
import useSWRFetcher from '../../libs/useSWRFetcher';

export interface SeriesCardImageProps {
  title: string;
  id: number;
  heroImage: string;
  gridType?: 'webcasts' | 'summits' | 'series' | 'channels';
  pageType?: string // need to be able to check what page i.e search so we can build new image params
  priorityStatus?: boolean;
}

const SeriesCardImage: FC<SeriesCardImageProps> = ({
  id,
  heroImage,
  title,
  gridType,
  pageType,
  priorityStatus,
}) => {
  let seriesImageSrc = '';

  const socialMediaAPI = '/api/socialMediaLogo';

  const fullUrl = swrUrlParser(socialMediaAPI, { seriesId: id });

  const { data } = useSWR(fullUrl, useSWRFetcher());

  if (data === undefined || (!data && !data?.['socialMediaLogo'])) {
  } else if (data && data?.['socialMediaLogo']) {
    seriesImageSrc = data?.['socialMediaLogo'];
  } else {
    seriesImageSrc = heroImage;
  }

  return (
    <>
      <CardsImage
        key={`CardsImage-` + id}
        id={id}
        href={seriesImageSrc}
        title={title}
        gridType={gridType}
        pageType={pageType}
        imageType={'full'}
        priorityStatus={priorityStatus}
      />
    </>
  );
};

export default SeriesCardImage;
